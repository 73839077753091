import moment from 'moment';
import { addDays, differenceInHours, differenceInMinutes } from 'date-fns';
import { EMPTY_INPUT, FABRIC_PRODUCTION_TYPE, MAX_COMPOSITION_PERCENTAGE, REQUIREMENT_TYPE } from 'src/constants';
import { PRODUCTION_SUB_TYPE } from 'src/constants/quoute';
import { pick, round } from 'lodash';
import { POLYESTER_LABEL } from 'src/components/PolyesterRequestForm/config';
const isInternational=localStorage.getItem('isInternational')==='1';
export const nounWord = (word: string, count: string | number | Number, addedString: string = 's' ): string => {
  if(word === 'All open'){
    return word
  }
  return count <= 1 ? word : `${word}${addedString}`;
};

export const capsFirstLetter = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const formatNumWithCommas = (x?: string | number): string => {
  return x === undefined ? '' : x.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
};

export const DATE_MMMDDYYYYY = (date?: string): string => {
  return date === undefined ? '' : moment(date).format('ll');
};

export const DATE_MMYYYYY = (date?: string): string => {
  return date === undefined ? '' : moment(date).format('DD MMM, YYYY');
};

export const TIME_HHMM_AA = (date?: string): string => {
  return date ? moment(date).format('LT') : '';
};

interface getLinkTypes {
  id?: number;
  title?: string;
  name?: string;
  url?: string;
  isFinalDispatch?: boolean;
  dispatchNumber?: number;
}

export const getLink = (
  finder: string,
  arr?: Array<{ title: string; url: string }>
): getLinkTypes | undefined => {
  return arr
    ?.filter((data) => {
      return data.title === finder;
    })
    .pop();
};

export const orderValueCalc = (
  quantity: string,
  quotedUnitPrice: string,
  taxPercentage: string
): string =>
  (
    Number(quantity) * Number( isInternational ?Number(quotedUnitPrice).toFixed(2):quotedUnitPrice) +
    Number(quantity) * Number(quotedUnitPrice) * (parseFloat(taxPercentage) / 100)
  ).toFixed(2);

export const gstPattern = (gstNumber: string): string => {
  return `${gstNumber.slice(0, 2)} ${gstNumber.slice(2, 12)} ${gstNumber.slice(12, 15)}`;
};

export const phoneNumberPattern = (phoneNumber: string): string => {
  return `${phoneNumber.slice(0, 9)} ${phoneNumber.slice(9, 14)}`;
};

export const capsAll = (word: string): string => {
  return word.toUpperCase();
};

export const calcExpiry = (createdOn: string, quoteValidity: number): boolean | string => {
  const date = new Date(createdOn);

  const expiryDate = new Date(new Date(addDays(date, quoteValidity)).setHours(23, 59, 59, 999));

  if (expiryDate <= new Date()) return 'expired';

  const differenceInHour = differenceInHours(expiryDate, new Date());
  if (differenceInHour > 48) return false;
  if (differenceInHour === 1) return `${differenceInHour} hr`;
  if (differenceInHour <= 48 && differenceInHour !== 0) return `${differenceInHour} hrs`;

  const differenceInMins = differenceInMinutes(expiryDate, new Date());
  if (differenceInMins === 1) return `${differenceInMins} min`;
  if (differenceInMins > 1) return `${differenceInMins} mins`;

  return false;
};

export const handleEmptyInput = (inputValue: string): string => {
  return inputValue || EMPTY_INPUT;
};

export const isWebView = !!(window as any).ReactNativeWebView;
export const sendAppLinkEvent = (event: string): void => {
  if (isWebView) {
    (window as any).ReactNativeWebView.postMessage(JSON.stringify({ eventType: event }));
  }
};
export const checkFileType = (fileName: string, fileType: string): boolean => {
  if (fileName.split('.').pop() === fileType) {
    return true;
  } else {
    return false;
  }
};
export const splitIntoChunk = (arr: Array<Object>): Array<Object> => {
  let ans = [];
  for (let i = 0; i < arr.length; i += 2) {
    let tempArray;
    tempArray = arr.slice(i, i + 2);

    ans.push(tempArray);
  }
  return ans;
};
export const requestLinkUrl = (path: String, data: any): string => {
  let link = path ? path + '/?' : '';
  let newData = Object.keys(data);
  newData.map((key, index) => {
    link += `${key}=${data[key]}${index === newData.length - 1 ? '' : '&'}`;
  });
  return link;
};

export const getDifferenceMinutes = (QualityRefreshedTime?: string | null): number =>
  moment().diff(moment(QualityRefreshedTime), 'minute');
export const getCurrentIsoString = (): string => moment().toISOString();

export const objectCleaner = (obj:any) => { 
  for (const [key,value] of Object.entries(obj)) {
    if (!value) {
      delete obj[key];
    }
  }
  return obj;
}

export const __weavingQueries = (productionSubType:any) => {
  if(productionSubType === PRODUCTION_SUB_TYPE.Greige || productionSubType === PRODUCTION_SUB_TYPE.YarnDyed){
    return true;
  } 
  return false;
}

export const __computeFabricCost = (quantity:number,quotedUnitPrice:number,logisticsCost:number): number => {
  return round(quantity * (quotedUnitPrice - logisticsCost), 2);
}

export const __computeConstructionAndThreadCount = (val1:string,val2:string): string => {
  return val1 ? `${val1} x ${val2}` : '--'
}

export const __getCurrencySymbol = (isInternational?:Number):string => {
  if(isInternational){
    return '$'
  }
  return ' ₹'
}
export const __getUnit = (isInternational?: Number): string => (isInternational ? 'yd' : 'm');

export const blurOnScroll = (event: any) => event.target.blur();

export const __bankDetailsLabel = (isInternational?: Number): string =>
  isInternational ? 'Swift code' : 'IFSC code';

export const __getBankDetailsCode = (
  isInternational: Number,
  swiftCode: string,
  ifsc: string,
): string => (isInternational ? swiftCode : ifsc);

export const __supplierUnitPriceVisible = (
  isInternational?: Number,
  price?: Number,
  tat?: Number,
  supplierId?: Number,
) => {
  return price ? (isInternational && tat && supplierId) || !isInternational : false;
};
export const certificationTransformer = (certification: any) => {
  const obj:{[key: number]: string} = {};
  certification.map((val: {value: number, label: string}) => {
    obj[val.value] = val.label;
    return null;
  });
  return obj;
};

export const checkValidFormValidations = (values: {}, keysToBeValidated: string[]): boolean => {
  const picked: any = pick(values, keysToBeValidated);
  return !(Object.keys(picked).length > 0);
};

export const checkValidComposition = (obj: any, isCottonCase: boolean, isDenimCase: boolean) => {
  let totalSum = 0;
  const maxPercentage = Number(obj[0].percentage);
  const maxCategoryLabel = obj[0].label;
  for (let i = 0; i < obj.length; i++) {
    if (
      !obj[i].categoryId ||
      !obj[i].percentage ||
      (obj[i].percentage && +obj[i].percentage <= 0)
    ) {
      return { isValid: false };
    }
    totalSum += Number(obj[i].percentage);
    if (totalSum > 100) {
      return { isValid: false, isGreater: true };
    }
  }

  if (totalSum > 100) {
    return { isValid: false, isGreater: true };
  }

  if (totalSum < 100) {
    return { isValid: false, isLesser: true };
  }
  if (totalSum !== 100) {
    return { isValid: false };
  }
  if (totalSum === 100 && isDenimCase) {
    return { isValid: true };
  }

  if (isCottonCase) {
    if (
      (maxCategoryLabel === 'Cotton' || maxCategoryLabel === POLYESTER_LABEL) &&
      maxPercentage >= MAX_COMPOSITION_PERCENTAGE
    ) {
      return { isValid: true };
    } else {
      return { isValid: false };
    }
  } else {
    if (maxCategoryLabel === 'Viscose' && maxPercentage >= MAX_COMPOSITION_PERCENTAGE) {
      return { isValid: true };
    } else {
      return { isValid: false };
    }
  }
};
export const requirementTypeValue = (
  productionType: number,
  productionSubType: number,
  overRideRequirementTypeText?: string,
): string => {
  const isFinishedFabric = productionType === FABRIC_PRODUCTION_TYPE.FINISHED_FABRIC;
  return `${isFinishedFabric ? 'Finished fabric -' : ''} ${
    overRideRequirementTypeText || REQUIREMENT_TYPE[productionSubType]
  }`;
};

export const removeCharacter = (str = '', chr = '') => (!str ? '' : String(str).replace(chr, '').trim());
export const checkIfSCCertificateRequied = (values: string[] = []) => {
  const certifications = ['OCS-Organic', 'GOTS-Organic', 'Organic 100', 'Organic'];
  return certifications.some((cert) => values?.includes(cert));
};

export const specificCertifications = [
  'BCI',
  'Oeko-Tex',
  'OCS-Organic',
  'GOTS-Organic',
  'Organic 100',
  'Organic',
];